import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 票务
export const reqGetTicketListPage = params => { return instance.get(`${SERVER_HOST}/admin/ticket/page`,{ params: params }) };
export const reqDeleteTicket= params => { return instance.delete(`${SERVER_HOST}/admin/ticket/delete/`+params.id, params) };
export const reqBatchDeleteTicket = params => { return instance.post(`${SERVER_HOST}/admin/ticket/batchdelete/`, params) };
export const reqAddTicket = params => { return instance.post(`${SERVER_HOST}/admin/ticket/add`, params) };
export const reqEditTicket = params => { return instance.post(`${SERVER_HOST}/admin/ticket/edit`, params) };
export const reqGetTicketOne = params => { return instance.get(`${SERVER_HOST}/admin/ticket/one`, { params: params }) };
export const reqEditStatusTicket = params => { return instance.post(`${SERVER_HOST}/admin/ticket/editStatus`, params) };
